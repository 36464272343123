.list-footer-menu {
  li {
    &:not(:first-of-type) {
      margin-top: px2rem(12.5);
      @include media-breakpoint-up(sm) {
        margin-top: px2rem(10);
      }
    }

    .link {
      text-decoration: none;
      transition: $transition-out;
      position: relative;
      color: $color-text;
      padding-left: px2rem(20);
      cursor: pointer;

      &:before {
        content: '';
        width: px2rem(10);
        height: 1px;
        z-index: -1;
        background: $color-secondary;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(50%);
        transition: $transition-out;
      }

      &:hover {
        transition: $transition-in;
        color: $color-primary;

        &:before {
          //height: 100%;
          //opacity: 0;
          z-index: -1;
          transform: rotate(90deg);
          height: px2rem(2);
          transition: $transition-in;
          background: $color-primary;
          //animation-name: linkHover;
          //animation-duration: $transition-in;
          //animation-fill-mode: both;
        }
      }
    }
  }
}

.list-breadcrumb {
  margin-top: px2rem(-30);
  border-bottom: 1px solid $color-secondary-lighter;

  li {
    display: inline-block;
    padding: px2rem(10) px2rem(5);
    font-weight: 600;
    color: $color-primary;

    &:not(:last-of-type) {
      &:after {
        content: '';
        width: 5px;
        height: 10px;
        background: $color-secondary-light;
        display: inline-block;
        transform: skew(-30deg);
        margin: px2rem(0) px2rem(15) px2rem(0) px2rem(17.5);
      }
    }

    .link {
      color: $color-secondary;
      text-decoration: none !important;
      transition: $transition-out;

      &:hover {
        color: $color-primary;
        transition: $transition-in;
      }
    }
  }
}

.list-check {
  @include media-breakpoint-up(sm) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0px -15px;
  }

  li {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-top: px2rem(30);
    @include media-breakpoint-up(sm) {
      padding: 0px 15px;
      width: 50%;
    }

    &:before {
      content: '\f00c';
      font-family: $font-family-secondary;
      border-radius: $border-radius-secondary;
      width: px2rem(50);
      height: px2rem(50);
      flex-shrink: 0;
      margin-right: px2rem(15);
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-secondary-lighter;
      color: $color-primary;
    }
  }
}

.list-numbered {
  counter-reset: listNumbered;

  li {
    margin-top: px2rem(20);
    padding-left: px2rem(60);
    position: relative;

    &:before {
      counter-increment: listNumbered;
      content: counter(listNumbered) '.';
      border-radius: $border-radius-secondary;
      width: px2rem(40);
      height: px2rem(40);
      flex-shrink: 0;
      margin-right: px2rem(15);
      display: flex;
      align-items: center;
      font-size: px2rem($default-font-size - 1);
      font-weight: 600;
      justify-content: center;
      background: $color-secondary-lighter;
      color: $color-primary;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.list-primary {
  margin-top: px2rem(40);

  li {
    margin-top: px2rem(12.5);
    @include media-breakpoint-up(sm) {
      margin-top: px2rem(10);
    }

    text-decoration: none;
    transition: $transition-out;
    position: relative;
    color: $color-text;
    padding-left: px2rem(20);

    &:before {
      content: '';
      width: px2rem(10);
      height: 1px;
      z-index: -1;
      background: $color-secondary;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(50%);
      transition: $transition-out;
    }
  }
}

.list-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  text-transform: uppercase;
  font-weight: 600;

  &:not(.no-hover) {
    li {
      cursor: pointer;
    }
  }

  &.no-hover {
    li {
      pointer-events: none;
    }
  }

  li {
    white-space: nowrap;
    border-radius: $border-radius-tertiary;
  }

  @each $size, $resolution in $grid-breakpoints {
    $i: index(($grid-breakpoints), ($size $resolution));
    &.list-tags-#{$size} {
      margin: -2px * $i -2px * $i -2px * $i -2px * $i;
      @extend .font-size-#{$size};

      li {
        padding: 5px * $i 10px * $i 5px * $i 10px * $i;
        margin: 2px * $i 2px * $i 2px * $i 2px * $i;
      }
    }
  }
  @each $colorParent, $colorChildren in $allColors {
    @each $colorName, $colorHex in $colorChildren {
      $i: index(($colorChildren), ($colorName $colorHex));
      &.list-tags-#{$colorName} {
        @if ($i == 1) {
          @include tagsColor($colorHex, $color-white, nth(nth($colorChildren, 4), 2), $color-white);
        } @else {
          @include tagsColor($colorHex, $color-white, nth(nth($colorChildren, 1), 2), $color-white);
        }
      }
    }
  }
}
