@function px2rem($font-size, $base-font-size: $default-font-size) {
  @return $font-size / $base-font-size + rem
}

/**
 * Aspect ration
 * @param {$width} width
 * @param {$height} height
 */
@mixin aspectRation($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .inner-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin tagsColor($background, $color, $backgroundHover, $colorHover) {
  li {
    color: $color;
    background-color: $background;
    transition: $transition-out;
  }
  &:not(.no-hover) {
    li {
      &.active,
      &:hover {
        color: $colorHover;
        background-color: $backgroundHover;
        transition: $transition-in;
      }
    }
  }
}

/**
 * Form primary
 * @param {$backgroundColor} background color
 * @param {$borderColor} border color
 * @param {$prependColor} prepend color
 */
@mixin formPrimaryColor($backgroundColor, $borderColor, $prependColor, $labelColor) {
  .NewStyleinput-group {
    background: $backgroundColor;
    border-color: $borderColor;

    .NewStyleinput-group-prepend {
      .NewStyleinput-group-text {
        color: $prependColor;
      }
    }
  }

  .label {
    color: $labelColor;
  }

  .NewStylefeedback {
    color: $prependColor;
  }
}

/**
 * Alert Style
 * @param {$backgroundColor} background color
 * @param {$borderColor} border color
 * @param {$colorText} text color
 */
@mixin alerPrimaryColor($colorBackground, $colorBorder, $colorText) {
  border-color: $colorBorder;
  background-color: $colorBackground;
  color: $colorText;
}
