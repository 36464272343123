header {
  position: relative;

  #menu-wrapper {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform: translateZ(0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    will-change: transform;
    border: 0px;
    pointer-events: none;
    margin: 0px;
    z-index: 9999;
    transition: $transition-out;
    padding: px2rem(15) px2rem(15);
    @include media-breakpoint-up(sm) {
      background: transparent;
      position: absolute;
      padding: px2rem(20) px2rem(20);
    }
    @include media-breakpoint-up(md) {
      padding: px2rem(25) px2rem(25);
    }
    @include media-breakpoint-up(md) {
      padding: px2rem(30) px2rem(30);
    }
    @include media-breakpoint-up(lg) {
      padding: px2rem(35) px2rem(35);
    }
    @include media-breakpoint-up(xl) {
      padding: px2rem(40) px2rem(40);
    }

    .header-container {
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      transform: translateZ(0);

      #headerMenu {
        flex-grow: 1;

        .navbar-nav {
          @include media-breakpoint-up(sm) {
            width: auto;
          }

          > li {
            &.nav-item {
              &:not(.nav-item-btn) {
                position: relative;

                > .nav-link {
                  cursor: pointer;
                  pointer-events: all;
                  transition: $transition-out;
                  padding: 15px 5px;
                  position: relative;
                  color: $white;
                  @include media-breakpoint-up(sm) {
                    padding: 15px 15px;
                  }
                  //@include media-breakpoint-up(md) {
                  //}
                  //@include media-breakpoint-up(lg) {
                  //}
                  &:hover {
                    color: $color-primary;
                  }

                  &.active {
                    font-weight: 600;
                  }
                }
              }
            }

            &.nav-item-btn {
              .nav-link {
                pointer-events: all;
                cursor: pointer;
                padding: 15px 10px;
                background: transparent;
                z-index: 2;
                color: $white;
                position: relative;
                margin-left: px2rem(15);
                font-size: px2rem($default-font-size - 4.5);
                @include media-breakpoint-up(sm) {
                  margin-left: px2rem(20);
                  padding: 15px 20px;
                  font-size: px2rem($default-font-size);
                }
                @include media-breakpoint-up(md) {
                  padding: 15px 25px;
                }
                @include media-breakpoint-up(lg) {
                  padding: 15px 30px;
                }

                &:before {
                  content: "";
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  transition: $transition-out;
                  border: 2px solid $white;
                  z-index: 1;
                  position: absolute;
                  transform: scale(1);
                  border-radius: $border-radius-tertiary;
                }

                &:after {
                  content: "";
                  width: 100%;
                  height: 100%;
                  transform: scale(.5);
                  right: 0;
                  opacity: 0;
                  top: 0;
                  transition: $transition-out;
                  background: $color-primary;
                  z-index: 1;
                  position: absolute;
                  border-radius: $border-radius-tertiary;
                }

                &:hover {
                  transition: $transition-in;

                  &:before {
                    transition: $transition-in;
                    transform: scale(.5);
                    opacity: 0;
                  }

                  &:after {
                    opacity: 1;
                    transform: scale(1);
                    transition: $transition-in;
                  }
                }

                .nav-item-btn-text {
                  position: relative;
                  z-index: 2;
                }
              }
            }

            //&.nav-item-dropdown {
            //  .dropdown-menu {
            //    margin: 0px;
            //    padding: 0px;
            //    border: 0px;
            //    border-radius: 0px;
            //    min-width: 100%;
            //
            //    li {
            //      a {
            //        padding: 15px 15px;
            //        display: block;
            //        color: $color-secondary-darker;
            //        transition: $transition-out;
            //        text-decoration: none !important;
            //        @include media-breakpoint-up(sm) {
            //        }
            //        //@include media-breakpoint-up(md) {
            //        //}
            //        //@include media-breakpoint-up(lg) {
            //        //}
            //      }
            //    }
            //  }
            //}
          }
        }
      }

      .navbar-brand {
        transition: $transition-out;
        font-size: 0px;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        pointer-events: all;
        width: 30vw;
        height: px2rem(30);
        @include media-breakpoint-up(sm) {
          margin-left: 0px;
          width: 40vw;
          height: px2rem(40);
        }
        @include media-breakpoint-up(md) {
          height: px2rem(45);
        }
        @include media-breakpoint-up(lg) {
          height: px2rem(50);
        }
        @include media-breakpoint-up(xl) {
          height: px2rem(60);
        }

        img,
        svg {
          height: 100%;
          max-width: 100%;

          * {
          }
        }
      }
    }
  }
}