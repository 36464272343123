//VARIABLES
$image-path: "~@/assets/images";
$font-path: "~@/assets/fonts" !default;
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts" !default;

$grid-columns: 60;

$default-font-size: 16;

$grid-breakpoints: (
        xs: 0,
        sm: 768px,
        md: 992px,
        lg: 1200px,
        xl: 1600px,
        xxl: 1920px
);

$container-max-widths: (
        sm: 768px,
        md: 992px,
        lg: 1170px,
        xl: 1500px
);

:root {
  --color-primary: #c4261c;
  --color-primary-lighter: #d2e7fa;
  --color-primary-light: #4ca0ea;
  --color-primary-dark: #166dba;
  --color-primary-darker: #10538d;
}

// COLORS
$color-primary: var(--color-primary);
$color-heading: #263238;
$color-text: #455a64;
$color-success: #218838;
$color-error: #e74c3c;

$white: #ffffff;
$black: #000000;

// Colors theme
$color-primary-lighter: var(--color-primary-lighter);
$color-primary-light: var(--color-primary-light);
$color-primary-dark: var(--color-primary-dark);
$color-primary-darker: var(--color-primary-darker);

$color-secondary: #BFBFBF;
$color-secondary-lighter: lighten($color-secondary, 20);
$color-secondary-light: lighten($color-secondary, 10);
$color-secondary-dark: darken($color-secondary, 10);
$color-secondary-darker: darken($color-secondary, 20);


// Colors complementary
$color-success: #2ecc71;
$color-success-lighter: lighten($color-success, 10);
$color-success-light: lighten($color-success, 5);
$color-success-dark: darken($color-success, 10);
$color-success-darker: darken($color-success, 20);

$color-danger: #c0392b;
$color-danger-lighter: lighten($color-danger, 10);
$color-danger-light: lighten($color-danger, 5);
$color-danger-dark: darken($color-danger, 10);
$color-danger-darker: darken($color-danger, 20);

$color-white: #ffffff;

$allColors: (color-primary: (color-primary:  $color-primary, color-primary-lighter : $color-primary-lighter, color-primary-light : $color-primary-light, color-primary-dark: $color-primary-dark, color-primary-darker: $color-primary-darker), color-secondary: (color-secondary: $color-secondary, color-secondary-lighter : $color-secondary-lighter, color-secondary-light : $color-secondary-light, color-secondary-dark: $color-secondary-dark, color-secondary-darker: $color-secondary-darker), color-success: (color-success: $color-success, color-success-lighter : $color-success-lighter, color-success-light : $color-success-light, color-success-dark: $color-success-dark, color-success-darker: $color-success-darker), color-danger: (color-danger: $color-danger, color-danger-lighter : $color-danger-lighter, color-danger-light : $color-danger-light, color-danger-dark: $color-danger-dark, color-danger-darker: $color-danger-darker));

// BORDER RAIDUSES
$border-radius-primary: 10px;
$border-radius-secondary: 50%;
$border-radius-tertiary: 50px;

// BOX SHADOWS
$box-shadow-primary: 1px 0 20px rgba(0,0,0,.08);

// TRANSITIONS
$transition-out: .4s;
$transition-in: .5s;

// ANIMATIONS
$animation-primary: 20s;

// FONTS
$font-family-primary: "Poppins";
$font-family-secondary: "Font Awesome 5 Free";

@function getShadeName($shade) {
  @if($shade == color){
    @return ""
  }

  @return -#{$shade}
}

