.NewStyletable-secondary {
  @extend .w-100;
  @extend .table-sm;
  @extend .table-color-secondary-lighter;

  $sizes: "xs", "sm", "md", "lg", "xl";

  thead {
    display: none;
    @include media-breakpoint-up(sm) {
      display: table-header-group;;
      text-align: left;
    }
    tr {
      th {
        font-weight: 700;

        &:first-of-type {
          border-top-left-radius: $border-radius-primary;
          border-bottom-left-radius: $border-radius-primary;
        }

        &:last-of-type {
          border-top-right-radius: $border-radius-primary;
          border-bottom-right-radius: $border-radius-primary;
        }
      }
    }
  }

  tbody {
    tr {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      @include media-breakpoint-up(sm) {
        display: table-row;
        text-align: left;
      }
      td {
        &:before{
          font-weight: 700;
          display: inline-block;
          content: attr(data-description) ": ";
          @include media-breakpoint-up(sm) {
            display: none;
          }
        }
        &:first-of-type {
          border-top-left-radius: $border-radius-primary;
          border-bottom-left-radius: $border-radius-primary;
          white-space: nowrap;
        }

        &:last-of-type {
          border-top-right-radius: $border-radius-primary;
          border-bottom-right-radius: $border-radius-primary;
        }

        &.hover-td {
          opacity: 0;
        }
      }

      &:hover {
        td {
          &.hover-td {
            opacity: 1;
          }
        }
      }
    }
  }

  &.table-row {
    tbody {
      tr {
        &:not(first-of-type) {
          border-top-width: 1px;
          border-top-style: solid;
        }
      }
    }
  }

  @each $colorParent, $colorChildren in $allColors {
    @each $colorName, $colorHex in $colorChildren {
      $i: index(($colorChildren), ($colorName $colorHex));
      &.table-#{$colorName} {
        tbody {
          tr {
            border-top-color: #{$colorHex};
            transition: $transition-out;
          }
        }

        &.table-hover {
          tbody {
            tr {
              &.active,
              &:hover {
                border-top-color: transparent;
                cursor: pointer;
                transition: $transition-in;
                background: $colorHex
              }
            }
          }
        }
      }
    }
  }

  @each $size in $sizes {
    $i: index($sizes, $size);
    &.table-#{$size} {
      tr {
        th {
          padding: 4px * $i 4px * $i 4px * $i 4px * $i;
        }

        td {
          padding: 4px * $i 4px * $i 4px * $i 4px * $i;
        }
      }
    }
  }
}