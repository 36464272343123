@import 'libs/variables';


// BOOTSTRAP COMPONENTS
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/utilities";

// EXTERNAL LIBS
@import  '~animate.css/animate.compat.css';
@import  '~@fortawesome/fontawesome-free/scss/fontawesome';
@import  '~@fortawesome/fontawesome-free/scss/solid';
@import  '~@fortawesome/fontawesome-free/scss/brands';

//// LIBS
@import "libs/animations";
@import "libs/mixins";
@import "libs/fonts";
@import "libs/typhography";
@import "libs/helpers";

//// MODULES
@import "modules/alert";
@import "modules/buttons";
@import "modules/footer";
@import "modules/form";
@import "modules/header";
@import "modules/list";
@import "modules/loader";
@import "modules/main-content";
@import "modules/mouse-scroll";
@import "modules/table";
@import "modules/mines";

