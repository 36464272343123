html,
body {
  color: $color-text;
  font-family: $font-family-primary;
  font-size: px2rem($default-font-size - 1.5);
  @include media-breakpoint-up(sm) {
    font-size: px2rem($default-font-size - 1);
  }
  @include media-breakpoint-up(md) {
    font-size: px2rem($default-font-size - .5);
  }
  @include media-breakpoint-up(lg) {
    font-size: px2rem($default-font-size);
  }
}

.h1 {
  margin: px2rem(0) px2rem(0) px2rem(30) px2rem(0);
  font-weight: 600;
  display: block;
  color: $color-heading;
  font-size: px2rem($default-font-size * 2);
  @include media-breakpoint-up(sm) {
    margin: px2rem(0) px2rem(0) px2rem(50) px2rem(0);
    font-size: px2rem($default-font-size * 2.5);
  }
  @include media-breakpoint-up(md) {
    font-size: px2rem($default-font-size * 3);
  }
  @include media-breakpoint-up(lg) {
    font-size: px2rem($default-font-size * 3.5);
  }
  @include media-breakpoint-up(xl) {
    font-size: px2rem($default-font-size * 4);
  }
}

.h2 {
  margin: px2rem(30) px2rem(0) px2rem(30) px2rem(0);
  font-size: px2rem($default-font-size * 1.5);
  font-weight: 600;
  display: block;
  color: $color-heading;
  @include media-breakpoint-up(sm) {
    font-size: px2rem($default-font-size * 2);
  margin: px2rem(50) px2rem(0) px2rem(50) px2rem(0);
  }
  @include media-breakpoint-up(md) {
    font-size: px2rem($default-font-size * 2.5);
  }
  @include media-breakpoint-up(lg) {
    font-size: px2rem($default-font-size * 3);
  }
}

.h3 {
  margin: px2rem(40) px2rem(0) px2rem(20) px2rem(0);
  color: $headings-color;
  display: block;
  font-size: px2rem($default-font-size * 1.5);
  @include media-breakpoint-up(sm) {
    font-size: px2rem($default-font-size * 2);
  }
  @include media-breakpoint-up(md) {
    font-size: px2rem($default-font-size * 2.5);
  }
  @include media-breakpoint-up(lg) {
    font-size: px2rem($default-font-size * 3);
  }
}

.h4 {
  margin: px2rem(30) px2rem(0) px2rem(12.5) px2rem(0);
  font-weight: 600;
  display: block;
  color: $headings-color;
  font-size: px2rem($default-font-size * 1.2);
  @include media-breakpoint-up(sm) {
    margin: px2rem(30) px2rem(0) px2rem(25) px2rem(0);
    font-size: px2rem($default-font-size * 1.3);
  }
  @include media-breakpoint-up(md) {
    font-size: px2rem($default-font-size * 1.4);
  }
  @include media-breakpoint-up(lg) {
    font-size: px2rem($default-font-size * 1.5);
  }
}

.h5 {
  margin: px2rem(20) px2rem(0) px2rem(10) px2rem(0);
  color: $headings-color;
  display: block;
  font-size: px2rem($default-font-size * 0.8);
  @include media-breakpoint-up(sm) {
    font-size: px2rem($default-font-size * 1.05);
  }
  @include media-breakpoint-up(md) {
    font-size: px2rem($default-font-size * 1.10);
  }
  @include media-breakpoint-up(lg) {
    font-size: px2rem($default-font-size * 1.15);
  }
}

.font-size-xs {
  font-size: px2rem($default-font-size * .75);
}

.font-size-sm {
  font-size: px2rem($default-font-size);
}

.font-size-md {
  font-size: px2rem($default-font-size * 1.25);
}

.font-size-lg {
  font-size: px2rem($default-font-size * 1.5);
}

.font-size-xl {
  font-size: px2rem($default-font-size * 1.75);
}

.font-size-xxl {
  font-size: px2rem($default-font-size * 2);
}

p {
  margin: 0px;
  line-height: px2rem(30);

  & + * {
    margin-top: 1.5rem;
  }
}