.minesweeper {
  .minesweeper-status {
    color: $color-primary;
    font-weight: 600;
    > * {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > a {
      @extend .btn-primary;
    }
  }

  .minesweeper-cell {
    background: $color-primary;
    color: $white;

    &.minesweeper-bomb {
      background: $color-primary-darker;
      &:before{
        content: "\f1e2";
        font-family: $font-family-secondary;
        color: $white;
      }
    }
    &.minesweeper-open{
      background: $color-primary-light;
    }
  }
}