@font-face {
    font-family: $font-family-primary;
    src: url($font-path + '/Poppins-Light.woff2') format('woff2'),
    url($font-path + '/Poppins-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-family-primary;
    src: url($font-path + '/Poppins-Regular.woff2') format('woff2'),
    url($font-path + '/Poppins-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-family-primary;
    src: url($font-path + '/Poppins-Medium.woff2') format('woff2'),
    url($font-path + '/Poppins-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-family-primary;
    src: url($font-path + '/Poppins-SemiBold.woff2') format('woff2'),
    url($font-path + '/Poppins-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-family-primary;
    src: url($font-path + '/Poppins-Bold.woff2') format('woff2'),
    url($font-path + '/Poppins-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
