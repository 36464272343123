.list-unstyled {
  margin: 0px;
}

.cursor-pointer{
  cursor: pointer;
}

.color-primary {
  color: $color-primary;
}

.color-primary-lighter {
  color: $color-primary-lighter;
}

.color-primary-light {
  color: $color-primary-light;
}

.color-primary-dark {
  color: $color-primary-dark;
}

.color-primary-darker {
  color: $color-primary-darker;
}

.bg-color-primary {
  background: $color-primary;
}

.bg-color-primary-lighter {
  background: $color-primary-lighter;
}

.bg-color-primary-light {
  background: $color-primary-light;
}

.bg-color-primary-darker {
  background: $color-primary-darker;
}

.bg-color-primary-dark {
  background: $color-primary-dark;
}

.color-secondary {
  color: $color-secondary;
}

.color-secondary-lighter {
  color: $color-secondary-lighter;
}

.color-secondary-light {
  color: $color-secondary-light;
}

.color-secondary-dark {
  color: $color-secondary-dark;
}

.color-secondary-darker {
  color: $color-secondary-darker;
}

.bg-color-secondary {
  background: $color-secondary;
}

.bg-color-secondary-lighter {
  background: $color-secondary-lighter;
}

.bg-color-secondary-light {
  background: $color-secondary-light;
}

.bg-color-secondary-darker {
  background: $color-secondary-darker;
}

.bg-color-secondary-dark {
  background: $color-secondary-dark;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-all {
  pointer-events: all;
}

.white-space-nowrap {
  white-space: nowrap;
}

.white-space-nowrap-xl {
  @include media-breakpoint-up(xl) {
    white-space: nowrap;
  }
}

.border-radius-primary {
  border-radius: 0px 0px $border-radius-primary $border-radius-primary;
  box-shadow: $box-shadow-primary;
  margin-top: px2rem(-5);
  @include media-breakpoint-up(sm) {
    border-radius: $border-radius-primary;
    margin-top: px2rem(0);
  }
}

.w-sm-20 {
  @include media-breakpoint-up(sm) {
    width: 20% !important;
  }
}

.w-sm-25 {
  @include media-breakpoint-up(sm) {
    width: 25% !important;
  }
}

.w-sm-50 {
  @include media-breakpoint-up(sm) {
    width: 50% !important;
  }
}

.w-sm-60 {
  @include media-breakpoint-up(sm) {
    width: 60% !important;
  }
}

.w-md-100 {
  @include media-breakpoint-up(md) {
    width: 100% !important;
  }
}

.w-sm-70 {
  @include media-breakpoint-up(sm) {
    width: 70% !important;
  }
}

.w-lg-70 {
  @include media-breakpoint-up(lg) {
    width: 70%;
  }
}

.w-xl-50 {
  @include media-breakpoint-up(xl) {
    width: 50% !important;
  }
}

.z-index-99 {
  z-index: 99 !important;
}

.disabled-link {
  cursor: default;
  pointer-events: none;
}

.overflow-inherit {
  overflow: inherit !important;
}

.space-wrapper-primary {
  margin: 0px -7.5px;

  > * {
    padding: 0px 7.5px;
  }
}

.space-wrapper-secondary {
  margin: -15px -15px;

  > * {
    margin: 15px 0px;
  }
}

.space-wrapper-tertiary {
  margin: -15px;

  > .inner-box {
    padding: 15px;
  }
}

.overflow-sm-inherit {
  @include media-breakpoint-up(sm) {
    overflow: inherit;
  }
}

.min-height-auto {
  min-height: auto !important;
}

* {
  outline: none !important;
}
