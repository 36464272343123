.NewStyleform-group {
  position: relative;
  cursor: default;
  margin: 0px 0px 20px 0px;
  @extend .form-group-sm;
  //@extend .form-group-color-secondary-light;

  $sizes: "xs", "sm", "md", "lg", "xl";

  &.read-only{
    pointer-events: none;
    opacity: .75;
  .NewStyleinput-group {
    background: $color-secondary-lighter;
  }
  }

  .label {
    display: block;
    font-weight: 500;
    //@extend .font-3x;
    //@include fontWeight(500);
  }

  .NewStyleinput-group {
    border: 1px solid $color-secondary-light;
    border-radius: $border-radius-primary;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    overflow: hidden;
    height: 100%;

    .NewStyleinput-group-prepend {
      height: 100%;
      flex-shrink: 0;

      .NewStyleinput-group-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: $color-secondary-light;
        height: 100%;
      }
    }

    .NewStyleform-control {
      outline: none;
      border: 0px;
      background: transparent;
      flex-grow: 1;
      @include size(100%, 100%);
      resize: none;

      &::placeholder {
        color: $color-secondary-light;
      }
    }

    &.flex-row-reverse {
      .NewStyleinput-group-prepend {
        .NewStyleinput-group-text {
          padding-left: 0px !important;
        }
      }
    }

    &:not(.flex-row-reverse) {
      .NewStyleinput-group-prepend {
        .NewStyleinput-group-text {
          padding-right: 0px !important;
        }
      }
    }
  }

  @each $colorParent, $colorChildren in $allColors {
    @each $colorName, $colorHex in $colorChildren {
      $i: index(($colorChildren), ($colorName $colorHex));
      &.form-group-#{$colorName} {
        @include formPrimaryColor($color-white, $colorHex, $colorHex, $colorHex);
      }
    }
  }

  @each $size in $sizes {
    $i: index($sizes, $size);
    &.form-group-#{$size} {
      .NewStyleinput-group-prepend {
        .NewStyleinput-group-text {
          padding: 10px 10px 10px 10px;
        }
      }

      .NewStyleform-control {
        @extend .font-size-#{$size};
        padding: 10px 10px 10px 10px;
      }

      .NewStylefeedback {
        //@extend .font-1x;
      }
    }
  }
}