.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  opacity: .75;
  border-radius: 50%;
  background: $color-primary;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 $transition-in infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 $transition-in infinite;
  opacity: 1;
}

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 $transition-in infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 $transition-in infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

.loader {
  text-align: center;

  &.loaded {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    transition: $transition-in;

    &.notloaded {
      top: auto;
      position: relative;
      opacity: 1;
    }
  }

  &.loader-fixed {
    left: 50%;
    top: 50%;
    position: fixed;
    background: $white;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%) translateY(-50%);
  }
}