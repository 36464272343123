.alert {
  font-weight: 500;
  padding: 15px;
  @include media-breakpoint-up(sm) {
    padding: 15px 30px;
    border-radius: $border-radius-tertiary;
  }
  @each $colorParent, $colorChildren in $allColors {
    @each $colorName, $colorHex in $colorChildren {
      $color: $colorHex;
      $colorBorder: map-get($colorChildren, "lighter");

      &.alert#{getShadeName($colorName)}{

        @include alerPrimaryColor($color, $colorBorder, $white);
      }
    }
  }
}
