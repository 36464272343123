@keyframes backgroundPulse {
  from {
    transform: scale(1);
    transform-origin: right top;
  }

  50% {
    transform: scale(1.025);
    transform-origin: right top;
  }
  to {
    transform: scale(1);
    transform-origin: right top;
  }
}

@keyframes animatePhone {
  from {
    transform: translateY(0%) translateZ(0);
  }
  50% {
    transform: translateY(-1vh) translateZ(0);
  }

  to {
    transform: translateY(0%) translateZ(0);
  }
}

@keyframes animatePhoneShadow {
  from {
    transform: scale(1);
    opacity: 1;
    transform-origin: center bottom;
  }
  50% {
    opacity: .5;
    transform: scale(.85);
    transform-origin: center bottom;
  }

  to {
    opacity: 1;
    transform: scale(1);
    transform-origin: center bottom;
  }
}


@keyframes linkHover {
  from {
    top: 50%;
    left: 0;
  }
  50% {
    top: 100%;
    width: 100%;
  }
  100% {
    top: 100%;
    width: 100%;
  }
}

