$width: 50px;
$height: 50px;

.mouse-wrapper {
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
    position: absolute;
    bottom: 2.5rem;
    right: 2.5rem;
  }

  .mouse {
    height: $height;
    width: $width;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    pointer-events: all;
    transition: $transition-out;

    &:before {
      content: "";
      border: 2px solid $color-secondary-light;
      border-radius: $border-radius-tertiary;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      transition: $transition-out;
    }

    &:after {
      content: "\f107";
      font-family: "Font Awesome 5 Free";
      font-size: 30px;
      line-height: 15px;
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translateX(-50%);
      animation-duration: 3s;
      animation-name: scrollDownArrow;
      animation-iteration-count: infinite;
      transition: $transition-out;
    }

    &:hover {
      &:before {
        border-color: $color-primary;
        transition: $transition-in;
      }
    }
  }
}

@keyframes scrollDownArrow {
  from {
    opacity: 0;
    top: 20%;
    color: $color-secondary-light;
  }

  45% {
    color: $color-primary;
    opacity: 1;
  }

  90% {
    opacity: 0;
    top: 50%;
  }

  to {
    opacity: 0;
    top: 0%;
  }
}