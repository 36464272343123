.btn-primary {
  pointer-events: all;
  padding: 15px 10px;
  display: inline-block;
  cursor: pointer;
  text-decoration: none !important;
  z-index: 2;
  color: $color-primary;
  box-shadow: none !important;
  outline: 0px !important;
  border: 0px !important;
  background: transparent !important;
  position: relative;
  font-size: px2rem($default-font-size - 4.5);
  @include media-breakpoint-up(sm) {
    padding: 15px 20px;
    font-size: px2rem($default-font-size);
  }
  @include media-breakpoint-up(md) {
    padding: 15px 25px;
  }
  @include media-breakpoint-up(lg) {
    padding: 15px 30px;
  }

  &.read-only{
    pointer-events: none;
    opacity: .75;
  }

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: transparent;
    transition: $transition-out;
    border: 2px solid $color-primary;
    z-index: 1;
    position: absolute;
    transform: scale(1);
    border-radius: $border-radius-tertiary;
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    transform: scale(.5);
    right: 0;
    opacity: 0;
    top: 0;
    transition: $transition-out;
    background: $color-primary;
    z-index: -1;
    position: absolute;
    border-radius: $border-radius-tertiary;
  }

  &:hover {
    transition: $transition-in;
    color: $color-white;

    &:before {
      transition: $transition-in;
      transform: scale(.5);
      opacity: 0;
    }

    &:after {
      opacity: 1;
      transform: scale(1);
      transition: $transition-in;
    }
  }
}

.btn-secondary{
  text-decoration: none;
  transition: $transition-out;
  position: relative;
  color: $color-text;
  background: transparent !important;
  cursor: pointer;
  display: inline-block;

  &:before {
    content: "";
    width: 100%;
    height: 1px;
    //z-index: -1;
    transition: $transition-out;
    background: $color-secondary;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &:hover {
    transition: $transition-in;
    color: $color-primary;

    &:before {
      transform: scale(0);
      transition: $transition-in;
      background: $color-primary;
    }
  }
}

.link-inherit{
  .h5{
    color: $headings-color !important;
  }
  color: $body-color !important;
  text-decoration: none !important;
}